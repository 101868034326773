import Keycloak from "keycloak-js";

import axios from "axios";

let keycloak = null;

const setKeycloak = async () => {
    const config = (await axios.get('/api/profile')).data;
    keycloak = new Keycloak(config)
}

const getKeycloak = () => {
    if (keycloak == null) {
        alert('Keycloak config not found')
        throw 'Keycloak config not fetched'
    } else {
        return keycloak
    }
}

export {getKeycloak, setKeycloak}