import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getKeycloak, setKeycloak } from './keycloak/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { configureAxios } from './util/configure-axios';

const root = ReactDOM.createRoot(document.getElementById('root'));

(async () => {

    await setKeycloak();

    root.render(
        <ReactKeycloakProvider authClient={getKeycloak()}
            initOptions={{ onLoad: 'login-required' }}
            onTokens={({ token }) => configureAxios(token)}>
            <App />
        </ReactKeycloakProvider>
    )

})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
